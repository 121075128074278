import * as React from "react";
import Layout from "../_components/Layout/Layout";
import MainTitle from "../_components/MainTitle/MainTitle";

const MubadalaInfo = () => {
    return (
        <Layout title="Mubadala Info" customClass="mubadala-info">
            <MainTitle>Mubadala Welcomes You</MainTitle>

            <div className="mubadala-info">
                <p className="content-letter">
                    On behalf of Mubadala Investment Company, welcome to the 2022 Mubadala Silicon Valley Classic. This is our fourth year as the title sponsor of the Mubadala Silicon Valley Classic - the longest-running, women-only professional tennis tournament in the world. We are incredibly proud to support an event with such a far-reaching legacy.
                </p>

                <p className="content-letter">
                    As an Abu Dhabi-based global investment company operating across more than 50 countries, Mubadala is committed to being at the forefront of responsible investing. To that end, we continue to foster and expand our partnerships around the world to leave a positive lasting impact on the communities where we invest.
                </p>

                <p className="content-letter">
                    Mubadala Capital is a global asset management firm and a wholly owned subsidiary of Mubadala Investment Company. With a dedicated Mubadala Capital ventures team in the Bay Area focused on investing in innovative technologies, we are proud to call Silicon Valley home.  
                    We hope you enjoy some world-class tennis.
                </p>

                <p className="all-best">All my best,</p>

                <div className="letter-footer">
                    <div className="signature">
                        <img src="/images/silicon-valley/signature.png"/>
                        <span>Ibrahim Ajami,</span>
                        <span>Head of Ventures and Growth</span>
                        <span><img className="mubadala-logo" src="/images/silicon-valley/mubadala-logo.png"/></span>
                    </div>

                    <img className="author-img" src="/images/silicon-valley/Ibrahim.jpg"/>
                </div>
            </div>
        </Layout>
    );
};

export default MubadalaInfo;
